<template>
  <div class="app">
    <div class="background-overlay"></div>
    <div class="center-content">
      <h1 :style="{ backgroundImage: gradientStyle }">{{ name }}</h1>
      <p>{{ message }} <span class="heart">❤️</span></p>
    </div>
  </div>
</template>

<script>
export default {
  name: "App",
  data() {
    return {
      name: "Nur Syamimi",
      message: "from IPG with",
      gradientStyle: "",
    };
  },
  mounted() {
    this.animateText();
  },
  methods: {
    animateText() {
      let r = 255;
      let g = 0;
      let b = 150;
      let direction = 1;

      setInterval(() => {
        if (r === 255 && g < 255 && b === 150) {
          g += 5 * direction;
        } else if (g === 255 && r > 0) {
          r -= 5 * direction;
        } else if (r === 0 && b < 255) {
          b += 5 * direction;
        } else if (b === 255 && g > 0) {
          g -= 5 * direction;
        } else if (g === 0 && r < 255) {
          r += 5 * direction;
        } else if (r === 255 && b > 150) {
          b -= 5 * direction;
        }

        this.gradientStyle = `linear-gradient(90deg, rgb(${r}, ${g}, ${b}), rgb(${g}, ${b}, ${r}))`;
      }, 100);
    },
  },
};
</script>

<style scoped>
body, html {
  height: 100%;
  margin: 0;
  font-family: 'Arial', sans-serif;
  background: #000; /* Fallback background color */
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}

.app {
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100vh;
  position: relative;
  z-index: 2;
}

.background-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: url('@/assets/background_ipg.jpg') no-repeat center center/cover;
  opacity: 0.1; /* Set opacity to 50% */
  z-index: 1;
}

.center-content {
  z-index: 2;
}

.center-content h1 {
  font-size: 5em;
  background-clip: text;
  -webkit-background-clip: text;
  color: transparent;
}

.center-content p {
  font-size: 1.5em;
  margin-top: -20px;
}

.heart {
  color: red;
  animation: heartbeat 1s infinite;
}

@keyframes heartbeat {
  0%, 100% { transform: scale(1); }
  50% { transform: scale(1.2); }
}
</style>
